import { gql } from '@apollo/client';

export type UpsResponse = {
  _id: string;
  code: string;
  center: [number, number] | null;
  occupancy: number;
  capacity: number;
  translation: Array<{
    lang: string;
    name: string;
    description: string | null;
  }>;
  cameras: Array<{
    _id: string;
    code: string;
    position: string;
    ups: string;
    description: string | null;
  }>;
  address: {
    streetNumber: string | null;
    streetNumberBis: string | null;
    streetType: string | null;
    streetName: string | null;
    specialPlace: string | null;
    postalCode: string | null;
    addressLocality: string | null;
    addressSubRegion: string | null;
    addressCountry: string | null;
    postOfficeBoxNumber: string | null;
  } | null;
  config: {
    fps: {
      maximumDuration: number | null;
      maximumAmount: number | null;
      system: string | null;
      notificationAuthority: string;
      reducedPriceDuration: number | null;
      finePrice: number | null;
      reducedFinePrice: number | null;
      recourseOrganization: {
        address: {
          streetNumber: string | null;
          streetNumberBis: string | null;
          streetType: string | null;
          streetName: string | null;
          specialPlace: string | null;
          postalCode: string | null;
          addressLocality: string | null;
          addressSubRegion: string | null;
          addressCountry: string | null;
          postOfficeBoxNumber: string | null;
        } | null;
        name: string | null;
        organizationId: string | null;
        url: string | null;
      } | null;
    } | null;
    prestoScan: {
      sitesLegacyId: string | null;
      rightsLegacyIds: Array<string> | null;
      entryTolerancePeriod: number | null;
      exitTolerancePeriod: number | null;
      isVerbalizationAllowed: boolean | null;
      showOccupancy: boolean | null;
      deletionDelay: {
        incomplete: number | null;
        complete: number | null;
        toCheck: number | null;
        unfined: number | null;
        fined: number | null;
      };
      outrageTreshold: {
        danger: number | null;
        warning: number | null;
        valid: number | null;
      };
      terminalId: string | null;
    } | null;
  } | null;
};

export type upsParams = {
  upsId: string;
};

export const upsGql = gql`
  query ups($upsId: String!) {
    ups(upsId: $upsId) {
      _id
      code
      center
      occupancy
      capacity
      cameras {
        _id
        code
        position
        ups
        description
      }
      translation {
        lang
        name
        description
      }
      address {
        streetNumber
        streetNumberBis
        streetType
        streetName
        specialPlace
        postalCode
        addressLocality
        addressSubRegion
        addressCountry
        postOfficeBoxNumber
      }
      config {
        fps {
          maximumDuration
          maximumAmount
          system
          notificationAuthority
          reducedPriceDuration
          finePrice
          reducedFinePrice
          recourseOrganization {
            address {
              streetNumber
              streetNumberBis
              streetType
              streetName
              specialPlace
              postalCode
              addressLocality
              addressSubRegion
              addressCountry
              postOfficeBoxNumber
            }
            name
            organizationId
            url
          }
        }
        prestoScan {
          entryTolerancePeriod
          exitTolerancePeriod
          sitesLegacyId
          rightsLegacyIds
          isVerbalizationAllowed
          showOccupancy
          deletionDelay {
            incomplete
            complete
            toCheck
            unfined
            fined
          }
          outrageTreshold {
            danger
            warning
            valid
          }
          terminalId
        }
      }
    }
  }
`;
