import { requestGQL, useMyUrl } from '@gimlite/watermelon';
import { Code } from '@gimlite/watermelon/components/code/code.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fragmentByRefGql, fragmentGql } from '../gql/fragments.gql';
import { Fragment } from '../types/fragment';

export const FragmentDetails = () => {
  const { fragmentId, fragmentRef } = useParams();
  const { getParamsUrl } = useMyUrl();

  const [fragment, setFragment] = useState<Fragment>();

  const load = useCallback(async () => {
    const search: any = {};
    if (fragmentId) search['fragmentId'] = fragmentId;
    if (fragmentRef) search['fragmentRef'] = fragmentRef;

    try {
      const response = await requestGQL({
        params: search,
        gql: fragmentId ? fragmentGql : fragmentByRefGql,
        render: (res) => res,
      });

      setFragment(() => response);
    } catch (err) {
      console.log({ err });
    } finally {
      console.log('loading finish');
    }
  }, [fragmentId, getParamsUrl, fragmentRef]);

  useEffect(() => {
    load();
  }, []);

  // useEffect(() => {
  //   const search: any = {};
  //   if (fragmentId) search['fragmentId'] = fragmentId;
  //   if (fragmentRef) search['fragmentRef'] = fragmentRef;

  //   (async () => {
  //     try {
  //       const response = await requestGQL({
  //         params: search,
  //         gql: fragmentGql,
  //         render: (res) => res,
  //       });

  //       setFragment(() => response);
  //     } catch (err) {
  //       console.log({ err });
  //     } finally {
  //       console.log('loading finish');
  //     }
  //   })();
  // }, [fragmentId, getParamsUrl, fragmentRef]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['code']],
          rows: ['1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'code' }}>
          <Widget config={{ title: 'Fragment debug' }}>
            <Code
              data={{
                defaultValue: JSON.stringify(fragment, null, 4),
              }}
              config={{ yMax: '100%', lang: 'json', readOnly: true }}
            />
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
