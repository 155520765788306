import { wizardService } from '@gimlite/watermelon';
import {
  Wizard,
  WizardType,
} from '@gimlite/watermelon/components/wizard/wizard.component';
import { useState } from 'react';
import { createUserService } from './createUser.machine';
import { AccountStep } from './steps/account.step';
import { TypeStep } from './steps/type.step';

export type CreateUserWizardContext = {
  accounts: Array<{
    _id: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    username?: string | null;
    roles: Array<string>;
  }>;
  selectedAccount: string | null;
  selectedType: string | null;
};

export const CreateUserWizard = () => {
  const [result, setResult] = useState<boolean>();
  const [resultError, setResultError] = useState<string>(
    "L'inscription n'a pas été réalisée !",
  );
  const [resultDetails, setResultDetails] = useState<
    Array<WizardType.Config.Details>
  >([]);

  return (
    <Wizard<CreateUserWizardContext>
      config={{
        title: 'Créer un utilisateur',
        withValidation: {
          state: result,
          success: "L'inscription a été réalisée !",
          error: resultError,
          details: resultDetails,
        },
      }}
      data={{
        defaultContext: {
          accounts: [],
          selectedAccount: null,
          selectedType: null,
        },
      }}
      handleEvent={{
        done: async (value) => {
          createUserService.send('REGISTER', { payload: value });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item config={{ title: 'Account', component: AccountStep }} />
      <Wizard.Item config={{ title: 'Type', component: TypeStep }} />
    </Wizard>
  );
};
