import {
  Badge,
  Blurb,
  Icon,
  ListFlatSingleCheck,
  notifService,
  requestGQL,
  Row,
  useTranslation,
  Zone,
} from '@gimlite/watermelon';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useEffect } from 'react';
import { QueryAccountsArgs } from '../../../../client/graphql';
import { CreateUserWizardContext } from '../createUser.wizard';
import { accountsGql, AccountsResponse } from '../gql/accounts.gql';

export const AccountStep = ({
  context: { accounts, selectedAccount },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateUserWizardContext>) => {
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const { accounts } = await requestGQL<
          QueryAccountsArgs,
          AccountsResponse
        >({
          params: { realm: 'IEM' },
          gql: accountsGql,
        });

        submit({
          accounts: accounts.map(
            ({ username, email, firstName, lastName, id, rolesFlat }) => ({
              _id: id,
              email,
              username,
              firstName,
              lastName,
              roles: rolesFlat,
            }),
          ),
        });
      } catch (error) {
        notifService.send('NOTIF', {
          content: error,
          mode: 'error',
        });
        submit({ accounts: [] });
      }
    })();
  }, []);

  useEffect(() => {
    validate(!!selectedAccount);
  }, [selectedAccount]);

  return (
    <Zone
      config={{
        zones: [['list']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'list' }}>
        <ListFlatSingleCheck<'identity' | 'module'>
          config={{
            pagination: 'scroll',
            columns: [
              { key: 'identity', label: t('identity'), size: '1fr' },
              {
                key: 'module',
                label: t('module'),
                size: 'min-content',
              },
            ],
            header: true,
          }}
          handleEvent={{
            paging: (paging) => {},
            click: () => {},
            checkItem: (_id) => {
              submit({ selectedAccount: _id });
            },
          }}
          data={{
            checkItem: selectedAccount,
            list: accounts.map(
              ({ _id, firstName, lastName, email, roles }) => ({
                _id,
                items: {
                  identity: (
                    <Row config={{ gap: 10, vertical: 'center' }}>
                      <Icon
                        config={{ size: 'list', type: 'faUserSolid' }}
                      ></Icon>
                      <Blurb
                        data={{
                          title: `${firstName} ${lastName}`,
                          description: email || '',
                        }}
                      />
                    </Row>
                  ),
                  module: (
                    <Row config={{ gap: 8 }}>
                      {roles.includes('app-view-module_equipment') && (
                        <Badge
                          config={{
                            text: 'equipment',
                            color: roles.includes('app-use-module_equipment')
                              ? 'success'
                              : 'disabled',
                          }}
                        ></Badge>
                      )}
                      {roles.includes('app-view-module_finance') && (
                        <Badge
                          config={{
                            text: 'finance',
                            color: roles.includes('app-use-module_finance')
                              ? 'success'
                              : 'disabled',
                          }}
                        ></Badge>
                      )}
                      {roles.includes('app-view-module_transaction') && (
                        <Badge
                          config={{
                            text: 'transaction',
                            color: roles.includes('app-use-module_transaction')
                              ? 'success'
                              : 'disabled',
                          }}
                        ></Badge>
                      )}
                    </Row>
                  ),
                },
              }),
            ),
            paging: {
              count: accounts.length,
              current: 1,
              limit: accounts.length,
            },
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
