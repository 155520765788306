import { gql } from '@apollo/client';
import {
  DurationDetail,
  ParkingRightsPaging,
  RecordEntity,
  SiteEntity,
  TicketError,
  Vehicle,
} from '../../../../client/graphql';

type ParkingRightsResponseList = ParkingRightsPaging['list'][number];

export type ParkingRightsResponse = {
  list: Array<{
    _id: ParkingRightsResponseList['_id'];
    state: ParkingRightsResponseList['state'];
    startDate: ParkingRightsResponseList['startDate'];
    endDate: ParkingRightsResponseList['endDate'];
    amount: ParkingRightsResponseList['amount'];
    error: Pick<TicketError, 'code' | 'message' | 'payload'>;
    currency: ParkingRightsResponseList['currency'];
    vehicle: Pick<Vehicle, 'lpn'>;
    ups: ParkingRightsResponseList['ups'];
    site: Pick<
      SiteEntity,
      'address1' | 'address2' | 'name' | 'city' | 'zipcode'
    >;
    durationDetails: Array<Pick<DurationDetail, 'duration' | 'type'>>;
    paymentRecordsEntity: Array<Pick<RecordEntity, 'type'>>;
    internalId: ParkingRightsResponseList['internalId'];
    siteId: ParkingRightsResponseList['siteId'];
  }>;
  paging: ParkingRightsPaging['paging'];
};

export const parkingRightsGql = gql`
  query parkingRights(
    $limit: Int
    $page: Int
    $order: String
    $plate: String
    $beginEnd: [DateTime!]
    $ups: [String!]
    $client: String
  ) {
    parkingRights(
      limit: $limit
      page: $page
      order: $order
      plate: $plate
      beginEnd: $beginEnd
      ups: $ups
      client: $client
    ) {
      list {
        _id
        consumer
        state
        startDate
        endDate
        amount
        error {
          code
          message
          payload
        }
        currency
        vehicle {
          lpn
        }
        ups
        site {
          address1
          address2
          name
          city
          zipcode
        }
        durationDetails {
          type
          duration
        }
        paymentRecordsEntity {
          type
        }
        internalId
        siteId
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
