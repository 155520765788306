import { gql } from '@apollo/client';

export type UpdateUpsResponse = {
  ups: {
    _id: string;
  };
};

export type UpdateUpsParams = {
  input: {
    upsId: string;
    code: string | null;
    type: string | null;
    maximumDuration: number | null;
    occupancy: number | null;
    capacity: number | null;
    translation: Array<{
      lang: string;
      name: string;
      description: string | null;
      pricing: string | null;
      opening: string | null;
      hint: string | null;
    }>;
    center: number[] | null;
    shape: {
      type: string;
      coordinates: number[];
    } | null;
    externalUpsId: string | null;
    address: {
      streetNumber: string;
      streetNumberBis: string | null;
      streetType: string;
      streetName: string;
      specialPlace: string | null;
      postalCode: string;
      addressLocality: string;
      addressSubRegion: string;
      addressCountry: string;
      postOfficeBoxNumber: string | null;
    } | null;
    config: {
      ticketsHub: {
        system: string;
      } | null;
      prestoScan: {
        sitesLegacyId: string | null;
        rightsLegacyIds: Array<string> | null;
        entryTolerancePeriod: number | null;
        exitTolerancePeriod: number | null;
        isVerbalizationAllowed: boolean | null;
        deletionDelay: {
          incomplete: number | null;
          complete: number | null;
          toCheck: number | null;
          unfined: number | null;
          fined: number | null;
        } | null;
        outrageTreshold: {
          danger: number | null;
          warning: number | null;
          valid: number | null;
        } | null;
        showOccupancy: boolean | null;
        terminalId: string;
      };
      fps: {
        system: string | null;
        notificationAuthority: string | null;
        reducedPriceDuration: number | null;
        finePrice: number | null;
        reducedFinePrice: number | null;
        recourseOrganization: {
          address: {
            streetNumber: string;
            streetNumberBis: string | null;
            streetType: string;
            streetName: string;
            specialPlace: string | null;
            postalCode: string;
            addressLocality: string;
            addressSubRegion: string;
            addressCountry: string;
            postOfficeBoxNumber: string | null;
          } | null;
          name: string | null;
          organizationId: string | null;
          url: string | null;
        } | null;
      };
    };
  };
};

export const updateUpsGql = gql`
  mutation updateUps($input: UpdateUpsDto!) {
    updateUps(input: $input) {
      ups {
        _id
      }
    }
  }
`;
