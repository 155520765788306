import { getOperationName } from '@apollo/client/utilities';
import { DocumentNode } from 'graphql';
import { useEffect, useState } from 'react';
import { findId } from '../functions/entities.function';
import { requestGQL } from '../functions/request.function';
import { Identifiable } from '../types/entities.type';
import { Minute } from '../utils/time.util';
import { useCache } from './useCache.hook';
import { useRootStore } from './useRootStore.hook';

export type ReadOfParams<T extends Identifiable> = {
  gql: DocumentNode;
  wsSubscriptions?: string[];
  queryBuilder?: (id: string) => object;
  event2Id?: (event: any) => string;
  render?: (res: T) => unknown;
  cache?: boolean;
  cacheTtl?: number;
  cacheKey?: string;
};

const DEFAULT_QUERY_BUILDER = (id: string) => ({ id });

export const useReadOf = <T extends Identifiable>({
  gql,
  wsSubscriptions,
  queryBuilder = DEFAULT_QUERY_BUILDER,
  event2Id = findId,
  render,
  cache = false,
  cacheTtl = 10 * Minute,
}: ReadOfParams<T>) => {
  const { GlobalStore } = useRootStore();

  const [id, setId] = useState<string>();
  const [details, setDetails] = useState<T>();

  const { passThrough: cachedRead } = useCache<T>({
    fn: async (id: string) => await queryDetails(id),
    maxAgeMs: cacheTtl,
    cacheKey: 'readOf_' + getOperationName(gql)!!,
  });

  const callback = (event) => {
    if (!id) return;

    if ((event2Id && event2Id(event) === id) || !event2Id) {
      refreshDetails();
    }
  };
  const queryDetails = async (id: string) => {
    if (id) {
      return requestGQL({
        operationName: getOperationName(gql)!!,
        params: queryBuilder(id),
        gql,
        render,
      });
    }
  };

  const refreshDetails = async () => {
    if (!id) {
      return () => { };
    }

    let item: any = null;
    if (cache) {
      item = await cachedRead(id);
    } else {
      item = await queryDetails(id);
    }

    if (item) {
      setDetails(item);
    }
  };

  useEffect(() => {
    if (GlobalStore.socket) {
      wsSubscriptions?.forEach((subscription) => {
        GlobalStore.socket?.on(subscription, callback);
      });

      return () => {
        wsSubscriptions?.forEach((subscription) => {
          GlobalStore.socket?.off(subscription, callback);
        });
      };
    }
  }, [id, GlobalStore.socket]);

  useEffect(() => {
    refreshDetails();
  }, [id]);

  return {
    details,
    setId,
    refreshDetails,
  };
};
