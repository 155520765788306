import { notifService, requestGQL } from '@gimlite/watermelon';
import { AutoComplete } from '@gimlite/watermelon/components/autocomplete/autocomplete.component';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Segmented } from '@gimlite/watermelon/components/segmented/segmented.component';
import {
  Select,
  SelectType,
} from '@gimlite/watermelon/components/select/select.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import {
  digitRule,
  isValidEmail,
  lowercaseLetterRule,
  minLengthPasswordRule,
  specialCharRule,
  uppercaseLetterRule,
} from '@gimlite/watermelon/functions/validator.function';
import { useCallback, useMemo } from 'react';
import {
  CreatePrestoScanWizardContext,
  langsItemsSelect,
  spaceBeetwenInput,
  spaceBeetwenTitle,
} from '../createPrestoScan.wizard';
import {
  ControlUserParams,
  ControlUserResponse,
  controlUserGql,
} from '../gql/control-user.gql';
import {
  ControlUsersParams,
  ControlUsersResponse,
  controlUsersGql,
} from '../gql/control-users.gql';
import './general.scss';

export const AgentsStep = ({
  context: { createAgent, modeAgent, searchAgent, listAgent },
  validate,
  submit,
}: WizardItemType.Config.Component<CreatePrestoScanWizardContext>) => {
  const formattedTranslateLang = useMemo(
    () =>
      langsItemsSelect.map(
        ({ value: langBase, label }: SelectType.Data.Item) => ({
          value: langBase,
          label,
        }),
      ),
    [createAgent],
  );

  const searchAgentFn = useCallback(
    async ({ usernamePattern }: { usernamePattern: string }) => {
      try {
        const response = (await requestGQL({
          params: { usernamePattern } as ControlUsersParams,
          gql: controlUsersGql,
        })) as ControlUsersResponse;

        submit({ listAgent: response.list });
      } catch (error) {
        notifService.send('NOTIF', {
          content: error,
          mode: 'error',
        });
        submit({ listAgent: [] });
      }
    },
    [],
  );

  const readAgentFn = useCallback(async ({ userId }: { userId: string }) => {
    try {
      const { _id, username, ups } = (await requestGQL({
        params: { userId } as ControlUserParams,
        gql: controlUserGql,
      })) as ControlUserResponse;

      submit({
        searchAgent: {
          _id,
          username,
          ups: ups || [],
        },
      });
    } catch (error) {
      notifService.send('NOTIF', {
        content: 'SERVER_PROBLEM',
        mode: 'error',
      });
      submit({
        searchAgent: {
          _id: null,
          username: null,
          ups: [],
        },
      });
    }
  }, []);

  return (
    <>
      <Zone
        config={{
          gap: {
            y: 2,
          },
          zones: [['filter'], ['form']],
          rows: ['min-content', '1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'filter' }}>
          <Segmented
            handleEvent={{
              option: (value) => {
                submit({
                  modeAgent: value as 'search' | 'create',
                  createAgent: {
                    firstName: null,
                    fpsAgentId: null,
                    fpsAgentName: null,
                    lang: null,
                    lastName: null,
                    password: null,
                    username: null,
                  },
                  searchAgent: {
                    _id: null,
                    username: null,
                    ups: [],
                  },
                });
              },
            }}
            data={{ selected: modeAgent }}
            config={{
              options: [
                {
                  value: 'search',
                  label: 'Trouver un agent',
                },
                {
                  value: 'create',
                  label: 'Créer un agent',
                },
              ],
            }}
          />
        </Zone.Area>

        <Zone.Area config={{ area: 'form' }}>
          {modeAgent === 'search' && (
            <Form
              data={{ value: searchAgent }}
              config={{ validateOnChange: true }}
              handleEvent={{
                change: (value) => {
                  submit({ searchAgent: value });
                },
                validate: (value) => {
                  validate(value);
                },
              }}
            >
              <Form.Structure
                config={{ layout: 'column', width: 'full', fill: true }}
              >
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'username',
                    label: "Email de l'agent",
                    rules: [{ required: true }],
                  }}
                >
                  <AutoComplete
                    handleEvent={{
                      input: (value) => {
                        searchAgentFn({
                          usernamePattern: value,
                        });
                      },
                      select(item) {
                        if (item?.value) {
                          readAgentFn({ userId: item.value });
                        }
                      },
                    }}
                    data={{
                      items: listAgent.map(({ _id, username }) => ({
                        value: _id,
                        label: username,
                      })),
                    }}
                    config={{
                      search: {
                        caseSensitive: false,
                        accentSensitive: false,
                        pattern: 'start',
                      },
                      height: 'xlarge',
                      width: 'full',
                      placeholder: 'control@user.com',
                    }}
                  />
                </Form.Item>
              </Form.Structure>
            </Form>
          )}

          {modeAgent === 'create' && (
            <Form
              data={{ value: createAgent }}
              config={{ validateOnChange: true }}
              handleEvent={{
                change: (value) => {
                  submit({ createAgent: value });
                },
                validate: (value) => {
                  validate(value);
                },
              }}
            >
              <Form.Structure
                config={{ layout: 'column', width: 'full', fill: true }}
              >
                <Write
                  data={{ item: "Identité personelle de l'agent" }}
                  config={{
                    mode: 'title-small',
                  }}
                ></Write>
                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'lang',
                      label: 'Langue de prédilection',
                      rules: [{ required: true }],
                    }}
                  >
                    <Select
                      data={{
                        items: formattedTranslateLang,
                      }}
                      config={{
                        height: 'xlarge',
                        width: 'medium',
                      }}
                    ></Select>
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'firstName',
                      label: 'Prénom',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'Jean',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'lastName',
                      label: 'Nom de famille',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'Clavin',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Space
                  config={{ count: spaceBeetwenTitle, way: 'vertical' }}
                ></Space>
                <Write
                  data={{ item: "Identité profesionnelle de l'agent" }}
                  config={{
                    mode: 'title-small',
                  }}
                ></Write>

                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'fpsAgentId',
                      label:
                        "Identifiant agent figurant sur le FPS reçu par l'usager",
                      rules: [
                        {
                          required: true,
                          pattern: /^\d{3}$/,
                        },
                      ],
                    }}
                  >
                    <Input
                      config={{
                        type: { name: 'number', min: 100 },
                        height: 'xlarge',
                        width: 'full',
                        placeholder: '154',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'fpsAgentName',
                      label: "Pseudonyme de l'agent pour l'ANTAI",
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'myAgentName',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Space
                  config={{ count: spaceBeetwenTitle, way: 'vertical' }}
                ></Space>
                <Write
                  data={{ item: 'Informations de connexion' }}
                  config={{
                    mode: 'title-small',
                  }}
                ></Write>

                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'username',
                      label: 'Adresse email',
                      rules: [{ required: true }, { validator: isValidEmail }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'control@user.com',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'password',
                      label:
                        'Mot de passe (min: 1, maj: 1, chiffre: 1, caract. spéciaux: 1, taille: 8)',
                      rules: [
                        { required: true },
                        {
                          validator: minLengthPasswordRule,
                        },
                        {
                          validator: uppercaseLetterRule,
                        },
                        {
                          validator: lowercaseLetterRule,
                        },
                        {
                          validator: digitRule,
                        },
                        {
                          validator: specialCharRule,
                        },
                      ],
                    }}
                  >
                    <Input
                      config={{
                        type: { name: 'password' },
                        height: 'xlarge',
                        width: 'full',
                      }}
                    />
                  </Form.Item>
                </Row>
              </Form.Structure>
            </Form>
          )}
        </Zone.Area>
      </Zone>
    </>
  );
};
