import { useNavigate } from '@gimlite/router';
import { Form, requestGQL } from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { FilterExpanded } from '@gimlite/watermelon/components/filter/filter.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { fragmentsGql } from '../gql/fragments.gql';
import { fragmentsRender } from '../projection/fragments.render';

export const FragmentSearch = () => {
  const [search, setSearch] = useState<any>();
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl({
    paramsFormatted: {
      fragmentRef: 'string',
      terminalId: 'string',
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: {
            limit: 15,
            ...getParamsUrl,
          },
          gql: fragmentsGql,
          render: (res) => fragmentsRender(res),
        });

        setSearch(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl]);

  return (
    <Page>
      <Widget config={{ title: 'Liste des fragments' }}>
        <Zone
          config={{
            zones: [['filter'], ['search']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item config={{ name: 'state', label: 'Etat' }}>
                  <Input />
                </Form.Item>
                <Form.Item config={{ name: 'ftype', label: 'Type' }}>
                  <Input />
                </Form.Item>
                <Form.Item
                  config={{ name: 'terminalId', label: 'Terminal ID' }}
                >
                  <Input />
                </Form.Item>
              </FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Rechercher', type: { value: 'submit' } }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'search' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(`/adm/fragments/${value._id}`);
                },
              }}
              data={search}
              config={{
                columns: [
                  {
                    title: 'consumer',
                    key: 'consumer',
                  },
                  {
                    title: 'terminalId',
                    key: 'terminalId',
                  },
                  {
                    title: 'Ref',
                    key: 'fragmentRef',
                  },
                  {
                    title: 'type',
                    key: 'type',
                  },
                  {
                    title: 'logDate',
                    key: 'logDate',
                  },
                  {
                    title: 'state',
                    key: 'state',
                  },
                ],
                actions: { read: true },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
