import { gql } from '@apollo/client';
import { SearchAccountsResponseDto } from '../../../../client/graphql';

export type AccountsResponse = {
  accounts: Array<
    Pick<
      SearchAccountsResponseDto['accounts'][number],
      | 'email'
      | 'firstName'
      | 'id'
      | 'emailVerified'
      | 'lastName'
      | 'rolesFlat'
      | 'username'
    >
  >;
};

export const accountsGql = gql`
  query accounts($realm: String!) {
    accounts(realm: $realm) {
      accounts {
        firstName
        lastName
        username
        email
        emailVerified
        id
        rolesFlat
      }
    }
  }
`;
