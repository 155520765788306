import {
  requestGQL,
  Space,
  Table,
  useMyUrl
} from '@gimlite/watermelon';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { Tab } from '@gimlite/watermelon/components/tab/tab.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TrunkAmountChart } from '../../../../charts/trunkAmount.chart';
import { TrunkCoinsChart } from '../../../../charts/trunkCoins.chart';
import { siteFinanceGql } from '../../gql/tab/finance.gql';
import { exportCollectionPdf } from '../../projection/finance-pdf.render';
import { readFinanceRender } from '../../projection/finance.render';
import { siteDetailsDefaultParams, SitesDetailsFilter } from '../sites.details';

export const SitesDetailsFinance = () => {
  const [read, setRead] = useState<any>();
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const { siteId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { ...siteDetailsDefaultParams, ...getParamsUrl, siteId },
          gql: siteFinanceGql,
          render: (res) => readFinanceRender(res),
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, siteDetailsDefaultParams, siteId]);

  return (
    <Col config={{ scrollY: true }}>
      <SitesDetailsFilter />
      <Space config={{ count: 2 }} />
      <Widget config={{ title: 'Collecte de pièces', yMax: 500 }}>
        <Table<any>
          handleEvent={{
            paging: (value: any) => {
              setParamsUrl({
                ...getParamsUrl,
                collectsPage: value.page,
                collectsLimit: value.limit,
              });
            },
          }}
          data={read?.collects}
          config={{
            columns: [
              {
                title: 'Date',
                key: 'logDate',
              },
              {
                title: 'Ref.',
                key: 'fragmentRef',
              },
              {
                title: 'Montant total',
                key: 'localAmount',
              },
              {
                title: 'Pièces rejetées',
                key: 'rejectedCoins',
              },
              {
                title: 'Pièces inhibées',
                key: 'inhibitedCoins',
              },
              {
                title: 'Card UID',
                key: 'cardUID',
              },
            ],
            actions: {
              export: (collectLine: any) => {
                exportCollectionPdf(collectLine, read);
              },
            },
          }}
        />
      </Widget>
      <Space config={{ count: 2 }} />

      <Widget config={{ title: 'Etat du coffre', yMax: 'min-content' }}>
        <Tab
          data={{
            items: [
              {
                key: 'amounts',
                label: 'Montants',
                children: (
                  <Col>
                    <TrunkAmountChart
                      config={{
                        startDate: getParamsUrl?.startDate,
                        endDate: getParamsUrl?.stopDate,
                      }}
                      data={read?.safeInfos?.amounts}
                    ></TrunkAmountChart>
                  </Col>
                ),
              },
              {
                key: 'coins',
                label: 'Pièces',
                children: (
                  <Col>
                    <TrunkCoinsChart
                      config={{
                        startDate: getParamsUrl?.startDate,
                        endDate: getParamsUrl?.stopDate,
                      }}
                      data={read?.safeInfos?.coins}
                    ></TrunkCoinsChart>
                  </Col>
                ),
              },
            ],
            selected: 'amounts',
          }}
        />
      </Widget>
    </Col>
  );
};
