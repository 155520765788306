import { gql } from '@apollo/client';

export const upsListGql = gql`
  query upsList($page: Int, $limit: Int, $order: String) {
    upsList(page: $page, limit: $limit, order: $order) {
      list {
        _id
        code
        maximumDuration
        shape
        client {
          slug
        }
        config {
          prestoScan {
            terminalId
          }
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
