import { Languages } from '../types/lang.type'

export const languages: Record<
  Languages,
  Array<{ label: string; value: Languages }>
> = {
  fr_FR: [
    { label: 'Anglais', value: 'en_EN' },
    { label: 'Français', value: 'fr_FR' },
    { label: 'Allemand', value: 'de_DE' },
    { label: 'Italien', value: 'it_IT' }
  ],
  en_EN: [
    { label: 'English', value: 'en_EN' },
    { label: 'French', value: 'fr_FR' },
    { label: 'German', value: 'de_DE' },
    { label: 'Italian', value: 'it_IT' }
  ],
  de_DE: [
    { label: 'Englisch', value: 'en_EN' },
    { label: 'Französisch', value: 'fr_FR' },
    { label: 'Deutsch', value: 'de_DE' },
    { label: 'Italienisch', value: 'it_IT' }
  ],
  it_IT: [
    { label: 'Inglese', value: 'en_EN' },
    { label: 'Francese', value: 'fr_FR' },
    { label: 'Tedesco', value: 'de_DE' },
    { label: 'Italiano', value: 'it_IT' }
  ]
}
