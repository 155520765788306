import { gql } from '@apollo/client';

export const fragmentsGql = gql`
  query fragments(
    $page: Int
    $limit: Int
    $order: String
    $state: String
    $ftype: String
    $siteId: String
    $terminalId: String
    $fragmentRef: String
  ) {
    fragments(
      page: $page
      limit: $limit
      order: $order
      state: $state
      terminalId: $terminalId
      type: $ftype
      siteId: $siteId
      fragmentRef: $fragmentRef
    ) {
      list {
        _id
        state
        logDate
        consumer
        type
        bucketId
        terminalId
        siteId
        batchId
        fragmentRef
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;

export const fragmentGql = gql`
  query fragment($fragmentId: String!) {
    fragment(fragmentId: $fragmentId) {
      _id
      state
      logDate
      consumer
      type
      bucketId
      terminalId
      siteId
      batchId
      fragmentRef
      body
    }
  }
`;

export const fragmentByRefGql = gql`
  query fragmentByRef($fragmentRef: String!) {
    fragmentByRef(fragmentRef: $fragmentRef) {
      _id
      state
      logDate
      consumer
      type
      bucketId
      terminalId
      siteId
      batchId
      fragmentRef
      body
    }
  }
`;
