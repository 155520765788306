import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type UseCascadeResult<T> = { title: string; response: T | Error };

export function useCascade<T>(
  promises: Array<
    (
      results: Array<UseCascadeResult<T>>,
      index: number,
    ) => Promise<UseCascadeResult<T>>
  >,
  ready: boolean = true,
) {
  const load = useRef<boolean>(false);
  const [results, setResults] = useState<Array<UseCascadeResult<T>>>([]);

  const isFinish = useMemo(
    () => (results.length === promises.length ? true : false),
    [results, promises],
  );

  const serial = useCallback(
    (
      promises: Array<
        (
          results: Array<UseCascadeResult<T>>,
          index: number,
        ) => Promise<UseCascadeResult<T>>
      >,
    ) =>
      promises.reduce(
        (acc: Promise<Array<UseCascadeResult<T>>>, func, index: number) =>
          acc.then((resultsAcc) =>
            func(resultsAcc, index)
              .then((success: UseCascadeResult<T>) =>
                resultsAcc.concat(success),
              )
              .catch((error) => resultsAcc.concat(error)),
          ),
        Promise.resolve([]),
      ),
    [],
  );

  useEffect(() => {
    if (!load.current && ready) {
      load.current = true;
      serial(promises).then((results: Array<UseCascadeResult<T>>) => {
        setResults(results);
      });
    }
  }, [promises, ready]);

  return { isFinish, results };
}
