import { Segmented as SegmentedAnt } from 'antd';
import type { SegmentedValue as SegmentedValueAnt } from 'antd/es/segmented';
import { Size } from '../../types/component.type';
import './segmented.component.scss';

export declare namespace SegmentedType {
  type Props = {
    className?: string;
    handleEvent: {
      option?: (value: SegmentedType.Data.Selected) => void;
    };
    data: {
      selected: SegmentedType.Data.Selected;
    };
    config: {
      options: SegmentedType.Config.Option[];
      size?: Extract<Size, 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'>;
    };
  };

  namespace Data {
    type Selected = SegmentedValueAnt;
  }

  namespace Config {
    type Option = {
      label: string;
      value: SegmentedType.Data.Selected;
      disabled?: boolean;
    };
  }
}

export const Segmented = ({
  handleEvent: { option } = {},
  data: { selected },
  config: { options, size = 'medium' },
  className = '',
}: SegmentedType.Props) => (
  <SegmentedAnt
    onChange={(value: SegmentedValueAnt) => {
      option && option(value);
    }}
    value={selected}
    options={options}
    className={`
        segmented
        segmented--size--${size} 
        ${className}
      `}
    block
  />
);
