import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import { DocumentNode } from 'graphql';
import { AssignAction, assign, createMachine, interpret } from 'xstate';
import { toCapitalizeCase, toUpperSnakeCase } from '../functions/string.function';


export const IDENTITY = <T>(input: T): T => input;

export const buildInitialContext = () => ({
  error: undefined
})

export type Actions = {
  actions: AssignAction<any, any, any>
};

export type Invoke = {
  id: string;
  src: string;
  onDone: {
    target: string;
    actions: AssignAction<any, any, any>;
  };
  onError: {
    target: string;
    actions: AssignAction<any, any, any>;
  };
};

export type State = {
  invoke: Invoke;
}

export type IdleState = {
  on: {
    [key: string]: {
      target: string;
    };
  }
}

export type WakeUp = {
  target: string;
  actions?: AssignAction<any, any, any>;
}

export type OffState = {
  on: {
    WAKEUP: WakeUp;
  }
}

export type FailureState = {
  on: {
    RETRY: {
      actions: AssignAction<any, any, any>;
      target: string;
    };
  }
}

export type States = {
  [key: string]: State | IdleState | FailureState | OffState;

  idle: IdleState;
  off: OffState;
  failure: FailureState;
}

export type IEMMachine = {
  statemachine: {
    predictableActionArguments: boolean,
    initial: string,
    id: string,
    context: Record<string, any>,

    states: States,

    on: {
      LOGOUT: Actions
    }
  }

  services: {
    services: {
      [key: string]: Function
    }

    guards: {
      ready: () => boolean,
    }
  }
}

export type Mapper<T, R = any> = (read: T) => R

export const buildIEMMachine = (id: string): IEMMachine => ({
  statemachine: {
    predictableActionArguments: true,
    id,
    initial: 'off',
    context: { ...buildInitialContext()  },

    states: {
      off: {
        on: {
          WAKEUP: { target: 'idle' },
        },
      },

      idle: {
        on: {
          KILL: { target: 'off' },
        }
      },

      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },

    on: {
      LOGOUT: {
        actions: assign({ ...buildInitialContext() }),
      },
    },
  },

  services: {
    services: {},

    guards: {
      ready: () => true,
    }
  }
})

export class IEMMachineBuilder {
  private machine: IEMMachine

  constructor(id: string) {
    this.machine = buildIEMMachine(id)
  }


  /**
   * Adds an operation to the machine builder.
   * If operation is named `doStuff`, the event that trigger it is names `DO_STUFF`.
   * 
   * @template R The type of the operation result.
   * @param name The name of the operation.
   * @param gql The GraphQL document node representing the operation.
   * @param mapper The function used to map the operation result.
   * @returns The updated machine builder.
   */
  public withOp<R>(name: string, gql: DocumentNode, mapper: Mapper<R> = IDENTITY): IEMMachineBuilder {
    this.machine.statemachine.states.idle.on[`${toUpperSnakeCase(name)}`] = { target: name }

    this.machine.statemachine.states[name] = {
      invoke: {
        id: name,
        src: name,
        onDone: {
          target: 'idle',
          actions: assign({
            [name]: (_: unknown, { data }: any) => data
          }),
        },
        onError: {
          target: 'failure',
          actions: assign({
            error: (_, { data } : any) => data,
            [name]: () => undefined,
          }),
        },
      },
    }

    this.machine.services.services[name] = (_: unknown, params: any) =>
      requestGQL({
        params,
        gql: gql,
        render: (res) => mapper(res)
      })

    this.machine.statemachine.context[name] = undefined

    return this
  }

  public withErrorHandler(handler: (ctx: any, { data } : any) => void): IEMMachineBuilder {
    this.machine.statemachine.states.failure.on.RETRY.actions = assign({
      error: handler
    })

    return this
  }

  /**
   * Adds a read operation to the machine builder names READ_<MACHINE_NAME>.
   */
  public withRead<R>(gql: DocumentNode, mapper: Mapper<R> = IDENTITY): IEMMachineBuilder {
    return this.withOp<R>(`read${toCapitalizeCase(this.machine.statemachine.id)}`, gql, mapper)
  }

  /**
   * Adds a read operation to the machine builder names SEARCH_<MACHINE_NAME>.
   */
  public withSearch<R>(gql: DocumentNode, mapper: Mapper<R> = IDENTITY): IEMMachineBuilder {
    return this.withOp<R>(`search${toCapitalizeCase(this.machine.statemachine.id)}`, gql, mapper)
  }

  /**
   * Adds a read operation to the machine builder names CREATE_<MACHINE_NAME>.
   */
  public withCreate<R>(gql: DocumentNode, mapper: Mapper<R> = IDENTITY): IEMMachineBuilder {
    return this.withOp<R>(`create${toCapitalizeCase(this.machine.statemachine.id)}`, gql, mapper)
  }

  /**
   * Adds a read operation to the machine builder names UPDATE_<MACHINE_NAME>.
   */
  public withUpdate<R>(gql: DocumentNode, mapper: Mapper<R> = IDENTITY): IEMMachineBuilder {
    return this.withOp<R>(`update${toCapitalizeCase(this.machine.statemachine.id)}`, gql, mapper)
  }

  public withWakeUp(wakeup: WakeUp): IEMMachineBuilder {
    this.machine.statemachine.states.off.on.WAKEUP = wakeup
    return this
  }

  public log() {
    console.log(`Machine ${this.machine.statemachine.id} exposes the following operations:\n`, 
      Object.keys(this.machine.statemachine.states.idle.on))
    console.log('MACHINE', this.machine)
    return this
  }

  public run() {
    return interpret(createMachine(this.machine.statemachine, this.machine.services as any)).start()
  }
}